import React from 'react'
// import 'About.css'
import { Link } from 'react-router-dom'

import image from '../../Assets/background.png'

import Banner2 from '../banner2/Bannerabout'

import Card from '../../pages/Card'
import Card1 from '../../pages/Card1'
import Footer from '../Footer';

const About = () => {
  return (
    <div className="container-fluid p-0">



    

  <Banner2/>
  <Card/>
  <Card1/>




  <Footer/>





  













{/* 
      <div className="card text-bg-dark border-0">
        <img src={image} className="card-img w-100" alt="background" style={{ height: '100vh', objectFit: 'cover' }} />
        <div className="card-img-overlay d-flex flex-column justify-content-center align-items-center text-center">
          <h5 className="card-title">Card title</h5>
          <p className="card-text">
            This is a wider card with supporting text below as a natural lead-in to additional content. 
            This content is a little bit longer.
          </p>
          <p className="card-text"><small>Last updated 3 mins ago</small></p>
        </div>
      </div>
      <div className="card text-bg-dark border-0">
        <img src={image} className="card-img w-100" alt="background" style={{ height: '100vh', objectFit: 'cover' }} />
        <div className="card-img-overlay d-flex flex-column justify-content-center align-items-center text-center">
          <h5 className="card-title">Card title</h5>
          <p className="card-text">
            This is a wider card with supporting text below as a natural lead-in to additional content. 
            This content is a little bit longer.
          </p>
          <p className="card-text"><small>Last updated 3 mins ago</small></p>
        </div>
      </div>
 */}




    </div>
  )
}

export default About
