import React from 'react'

import img1 from '../../Assets/item1.png'
import img2 from '../../Assets/item2.png'
import img3 from '../../Assets/item3.png'
import img4 from '../../Assets/item4.png'

import './Client.css'

const Client = () => {
  return (
    // <div className="row client1">


        
    // <div className='lg-4 d-flex align-content-between'>

    //   <div className='top'>
    //     <h3>OUR PARTNERS</h3>
    //     <h2>AND CLIENTS</h2>
    //   </div>

    //   <div className='lg-8 d-flex justify-content-around'>
    //     <img src={img1} alt='no image' />
    //     <img src={img2} alt='no' />
    //     <img src={img3} alt='no' />
    //     <img src={img4} alt='no' />
    //   </div>

    // </div>


    // </div>
<div className="row client1">
  {/* Left content */}
  <div className="col-lg-6 col-md-6 xs-d-flex-column col-sm-12 col-12 d-flex flex-column align-items-start mb-3">
    <div className="top">
      <h3>OUR PARTNERS</h3>
      <h2>AND CLIENTS</h2>
    </div>
  </div>

  {/* Right content */}
  <div className="col-lg-6 col-md-6 xs-d-flex-column col-sm-12 col-12 d-flex justify-content-center align-items-center">
    <div className="d-flex justify-content-around w-100">
      <img src={img1} alt="no image" />
      <img src={img2} alt="no image" />
      <img src={img3} alt="no image" />
      <img src={img4} alt="no image" />
    </div>
  </div>
</div>


  )
}

export default Client
