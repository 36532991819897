import React from "react";
// Importing Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

import "../App.css";

import mycar from "../Assets/car.png";
import Navbar from "./Navbar";
import Logo from "./Logo";
import Get_Taxi from "./Get_Taxi";

import Services from "../pages/Services";

import Tariff from "../pages/Tariff";

import Client from "../Components/client/Client";
import Footer from "../Components/Footer";

import Cards from "../pages/Card";
import Cards1 from "../pages/Card1";

import DownloadApp from "../Components/DownloadApp";
import Fordrivers from "../pages/Fordrivers";

import Testimonials from "../Components/Testimonials";

import { FaCar } from "react-icons/fa";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faMapMarkerAlt,
  faPhone,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";



import Steps from '../Components/step/Steps'

import Banner from '../Components/banner/Banner'


import Process from '../Components/process/Process'

// import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  return (
    <div className="home">
      {/* <Logo /> */}
      {/* <Navbar /> */}
      <Get_Taxi />

      {/* <CarImages /> */}




      <div className="yellow_part">


        <div className="car">
          <img src={mycar} alt="no_Car" />

        </div>

        
  <div>
        <div className="yellow-buttom">
          <div className="icons-cars">
            <div className="d-flex justify-content-center mb-4">
              <FontAwesomeIcon
                icon={faCar}
                size="2x"
                className="mx-3 text-primary"
              />
              <FontAwesomeIcon
                icon={faCar}
                size="2x"
                className="mx-3 text-success"
              />
              
              <FontAwesomeIcon
                icon={faCalendarAlt}
                size="2x"
                className="mx-3 text-info"
              />
              <FontAwesomeIcon
                icon={faCalendarAlt}
                size="2x"
                className="mx-3 text-info"
              />
            </div>
          </div>

          <button className="button"> GET TAXI </button>
        </div>
      </div>


</div>    

     
     
     <Cards />

      <Cards1 />


     

      <DownloadApp />


      <Banner/>



      {/* <Steps/> */}

      <Process/>




      <Fordrivers />

      <Testimonials />

      <Client />

      <Footer />
    </div>
  );
};

export default Home;
