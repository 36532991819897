import React from 'react';
import './Testimonials.css';


import man1 from '../Assets/man1.png'
import man2 from '../Assets/man2.png'



const Testimonials = () => {
  return (
    <div className="container my-5">


      
      


<div className="tariff">
      <div className="tariff-top">
        <h4>HAPPY CLIENTS'S</h4>
        <h2>TESTIMONIALS</h2>
      </div>
      </div>
      




      <h2 className="text-center mb-4">What Our Clients Say</h2>
      <div
        id="testimonialCarousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators ">
          <button
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>

        <div className="carousel-inner">
          {/* Slide 1 */}
          <div className="carousel-item active">
            <div className="testimonial-item">
              <img
                src={man1}
                className="rounded-circle testimonial-img mb-4"
                alt="client1"
              />
              <p className="testimonial-text">
                "This company is fantastic! The service they provide is top-notch.
                I couldn't be happier."
              </p>
              <h5 className="client-name">John Doe</h5>
              <p className="client-position">CEO, Company A</p>
            </div>
          </div>

          {/* Slide 2 */}
          <div className="carousel-item">
            <div className="testimonial-item">
              <img
                src={man2}
                className="rounded-circle testimonial-img mb-4"
                alt="client2"
              />
              <p className="testimonial-text">
                "Their customer support is excellent and the quality of their work
                speaks for itself."
              </p>
              <h5 className="client-name">Jane Smith</h5>
              <p className="client-position">Marketing Head, Company B</p>
            </div>
          </div>

          {/* Slide 3 */}
          <div className="carousel-item">
            <div className="testimonial-item">
              <img
                src={man1}
                className="rounded-circle testimonial-img mb-4"
                alt="client3"
              />
              <p className="testimonial-text">
                "A highly recommended service that ensures the best customer
                satisfaction!"
              </p>
              <h5 className="client-name">Alex Johnson</h5>
              <p className="client-position">Founder, Startup C</p>
            </div>
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
