



import React from 'react'
import Cards1 from '../../pages/Card1'
import Card from '../../pages/Card'

// import Navbar from '../Navbar'

import Logo from "../Logo"
import Navbar from "../Navbar"

import Client from "../client/Client"

import Footer from '../Footer';
import Testimonials from '../Testimonials'

const Sevices = () => {
  return (
    <div className='navbar-services'>

     {/* <Logo/> */}
     {/* <Navbar/> */}

         
      {/* <div className='navbar-top'>
        <p>Taxi // Services</p>
        <h1>Services</h1>
      </div> */}


  

   
      

      <Cards1/>

     

      
      <Card/>


      <Testimonials/>



      <Client/>

<div className='footer'>
<Footer/>

</div>




    </div>
  )
}

export default Sevices






