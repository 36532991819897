import React from 'react'
import "./Fordrivers.css";
import car from "../Assets/halfcar.png";

import Form from '../Components/form/Form.jsx';

const Fordrivers = () => {
  return (
    <div className='fordrivers'>



      <div className='fordrivers-details'>
        <h3 className='fw-bolder'>For Drivers</h3>
        <h1 className='fw-bolder'>DO YOU WANT TO EARN WITH US</h1>
        <p>Quisque sollicitudin feugiat risus, eu posuere ex euismod eu. Phasellus hendrerit, massa efficitur
           dapibus pulvinar, sapien eros sodales ante, euismod aliquet nulla metus a mauris.</p>

         <ul>
          <li>Luxury cars</li>
          <li>Fixed price</li>
          <li>Good application</li>
          <li>Stable orders</li>
          <li>cash payment</li>
          <li>minimum fee</li>
         </ul>




         {/* <button><h3>BECOME A DRIVER</h3></button> */}
         <Form/>

      </div>


  
      <div className='fordrivers-img'>
        <img src={car} alt="car" />
        
      </div>





    </div>
  )
}

export default Fordrivers



