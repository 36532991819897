import React from 'react';

import './Footer.css';

const Footer = () => {
  return (
        
    
   




    //   <div className='footer'>




  

     








    // <div className='conatiner'>


    //    <div className='about-us'>
    //     <h2>About Us</h2>
    //     <p>Our company is a leading provider of innovative solutions for the modern world. </p><p>We are
    //       committed to delivering high-quality products and services that meet the evolving needs of our customers.</p>
    //    </div>

    //    <div className='explore'>
         
    //      <h2>Explore</h2>
    //      <ul>
    //            <li>Gallery</li>
    //            <li>Testimonials</li>
    //            <li>Services</li>
    //            <li>Shortcodes</li>

    //      </ul>

    //    </div>

       

    //    <div className='exlore-more'>
    //    <h2>Explore</h2>
    //    <ul>
    //            <li>Gallery</li>
    //            <li>Testimonials</li>
    //            <li>Services</li>
    //            <li>Shortcodes</li>

    //      </ul>


    //    </div>



    //  <div  className='contactus'>
    //   <h2>Contact Us</h2>
    //   <p>Address: 123 Main St, Anytown, USA 12345</p>
    //   <p>Phone: 555-555-5555</p>
    //   <p>Email: <a href="mailto:info@example.com">info@example.com</a></p>
    //  </div>






    // </div>


        
         











    //    <div className='copyright'>
    //     <p> <strong>Like-themes </strong>2017 © All Rights Reserved <strong>Term of use</strong></p>
    //    </div>

    //   </div>




    <footer className="footer">
    <div className="container">
      <div className="footer-content">
        <div className="about-us">
          <h2>About Us</h2>
          <p>Our company is a leading provider of innovative solutions for the modern world.</p>
          <p>We are committed to delivering high-quality products and services that meet the evolving needs of our customers.</p>
        </div>

        <div className="explore">
          <h2>Explore</h2>
          <ul>
            <li>Gallery</li>
            <li>Testimonials</li>
            <li>Services</li>
            <li>Shortcodes</li>
          </ul>
        </div>

        <div className="explore-more">
          <h2>Explore</h2>
          <ul>
            <li>Gallery</li>
            <li>Testimonials</li>
            <li>Services</li>
            <li>Shortcodes</li>
          </ul>
        </div>

        <div className="contact-us">
          <h2>Contact Us</h2>
          <p>Address: 123 Main St, Anytown, USA 12345</p>
          <p>Phone: 555-555-5555</p>
          <p>Email: <a href="mailto:info@example.com">info@example.com</a></p>
        </div>
      </div>
    </div>

    <div className="copyright">
      <p><strong>Like-themes</strong> 2017 © All Rights Reserved <strong>Terms of use</strong></p>
    </div>
  </footer>








 


  );
};

export default Footer;
