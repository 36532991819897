

import React from 'react'
// import DownloadApp from '../DownloadApp'
import Card from '../../pages/Card'

import Client from "../client/Client"
import Footer from '../Footer';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCar, faMapMarkerAlt, faPhone, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

import Banner from '../banner/Banner'

const GetTaxi = () => {
  return (

     <div>




    <Banner/>


    <Card/>
    <Client/>

<div className='footer'>
<Footer/>

</div>


    </div>
  );
};

export default GetTaxi;




































































































