import React from 'react'
import myImage from '../Assets/logo.png';


const Logo = () => {
  return (
    <div className='logo'>


     <img src={myImage} alt="no_logo" />
    
    
    </div>
  )
}

export default Logo