import React from "react";

import img1 from "../Assets/mobile.png";

import "../Components/DownloadApp.css";

const DownloadApp = () => {
  return (
    <div className="DownloadApp">
      <div className="img-div">
        <div className="DownloadApp-top">
          <h2 className="fw-bolder">GET MORE BENEFITS</h2>

          <h1 className="fw-bold">DOWNLOAD THE APP</h1>
        </div>

        <div className="DownloadApp-buttom">
        <div className="DownloadApp-details-total">


          <div className="DownloadApp-details">
            <div>
              <h3 className="fw-bold">FAST BOOKING</h3>
              <p>
                Nam ac ligula congue, interdum enim sit amet, fermentum nisi.
              </p>
            </div>
            <div>
              <h3 className="fw-bold">EASY TO USE</h3>
              <p>
                Nam ac ligula congue, interdum enim sit amet, fermentum nisi.{" "}
              </p>
            </div>
          </div>

          <div className="DownloadApp-img  d-flex justify-content-center">
            <img src={img1} />
          </div>

          <div className="DownloadApp-details">
            <div>
              <h3 className="fw-bold">GPS SEARCHING</h3>
              <p>
                Nam ac ligula congue, interdum enim sit amet, fermentum nisi.
              </p>
            </div>
            <div>
              <h3 className="fw-bold">BONUSES FOR RIDE</h3>
              <p>
                Nam ac ligula congue, interdum enim sit amet, fermentum nisi.
              </p>
            </div>
          </div>


          </div>



        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
