// import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";






// import Navbar from "./Components/Navbar";
// import Home from "./Components/Home";
// import Get_Taxi from "./Components/Get_Taxi";



// import Footer from "./Components/Footer.jsx";






// function App() {
//   return (
//     <div className="App">
//       <Home />
//       {/* <Services />
//       <Tariff /> */}
//       {/* <Footer /> */}
//     </div>
//   );
// }

// export default App;





import Navbar from "./Components/Navbar.js";
import Logo from './Components/Logo.js'
import Home from "./Components/Home.js";
import Contact from "./Components/contact/Contact.js";
import About from "./Components/about/About.jsx";
import   Client from "../src/Components/client/Client.jsx"
// import Safety from "./pages/Safety/Safety.jsx";
 import Footer from "./Components/Footer.jsx";

 import Sevices from './Components/service/Sevices.jsx'

 import GetTaxi from './Components/gettaxi/Gettaxi.jsx'

 import about from './Components/about/About.jsx'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
         <Logo/>
         <Navbar /> 
        <Routes>
          <Route  path="/" element={<Home />} />
          <Route path="/Gettaxi" element={<GetTaxi />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Sevices />} />
          <Route path="/about" element={<About />} />

        </Routes>

        {/* <Client/> */}
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;






