import React from 'react'

import './Process.css'

import qr from '../../Assets/qr.png'

const Process = () => {
  return (
   


    <div className='container   d-flex   mt-5 mb-5'>
      





<div className='qr-contain-main'>
<h1>How does it work?</h1>

<ul className="timeline">
  <li className="timeline-card">
    <span aria-hidden="true" className="fa fa-stack fa-2x circle-custom" style={{ fontSize: "1.7rem" }}>
      <i aria-hidden="true" className="fa fas fa-circle fa-stack-2x"></i> &nbsp;
    </span>
    <div className="subcard">
      <h3 className="timeline-title">Fill in the order form</h3>
     
    </div>
  </li>
  <li className="timeline-card">
    <span aria-hidden="true" className="fa fa-stack fa-2x circle-custom" style={{ fontSize: "1.7rem" }}>
      <i aria-hidden="true" className="fa fas fa-circle fa-stack-2x"></i> &nbsp;
    </span>
    <div className="subcard">
      <h3 className="timeline-title">Wait for offers from drivers</h3>
     
    </div>
  </li>

  <li className="timeline-card">
    <span aria-hidden="true" className="fa fa-stack fa-2x circle-custom" style={{ fontSize: "1.7rem" }}>
      <i aria-hidden="true" className="fa fas fa-circle fa-stack-2x"></i> &nbsp;
    </span>
    <div className="subcard">
      <h3 className="timeline-title">Accept the best offer</h3>
      
    </div>
  </li>
  <li className="timeline-card">
    <span aria-hidden="true" className="fa fa-stack fa-2x circle-custom" style={{ fontSize: "1.7rem" }}>
      <i aria-hidden="true" className="fa fas fa-circle fa-stack-2x"></i> &nbsp;
    </span>
    <div className="subcard">
      <h3 className="timeline-title">Call the driver and discuss the details of your ride</h3>
      
    </div>
  </li>



</ul>
</div>










<div className="" style={{ backgroundColor: 'white' }}>
      <div className="qr-card">
        <p  className='fw-bold'>Scan the code with your camera to download the app</p>
        <img
          src={qr}
          alt="QR Code"
          className="qr-code"
        />
      </div>
    </div>

   










    </div>
  )
}

export default Process
