import React from 'react';
import './Cards1.css'; // Import the CSS file for custom styling




import building from "../Assets/building.png"


import car5 from "../Assets/cityrides.png";
import car6 from "../Assets/citytocity.png";







const Cards1 = () => {
  return (
    <div className="container my-4">

<div className="tariff">
      <div className="tariff-top">
        <h4>Welcome</h4>
        <h2>ONE APP, MANY SERVICES</h2>
      </div>
      </div>


      <div className="container">
  <div className="row justify-content-center">
    {/* Card 1 */}
    <div className="col-md-6 col-lg-3 d-flex justify-content-center mb-4">
      <div className="card h-100 border-0 card-hover">
        <img
          src={car5}
          className="card-img-top img-fluid mx-auto d-block my-3"
          alt="Card 1"
        />
        <div className="card-body">
          <h3 className="card-title  fw-bold">City rides</h3>
          <p className="card-text">
            Offer your fare
            Agree on a fair price directly with a driver
          </p>
        </div>
      </div>
    </div>

    {/* Card 2 */}
    <div className="col-md-6 col-lg-3 d-flex justify-content-center mb-4">
      <div className="card h-100 border-0 card-hover">
        <img
          src={car6}
          className="card-img-top img-fluid mx-auto d-block my-3"
          alt="Card 2"
        />
        <div className="card-body">
          <h3 className="card-title  fw-bold">City to City</h3>
          <p className="card-text">
            Comfortable rides to other cities: on your schedule and for a fair fare
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

        {/* Card 3 */}


      

        {/* Card 4 */}
      



      </div>
    
  );
};

export default Cards1;
