import React from 'react';
import './Bannerabout.css'; // You can create this CSS file for custom styling

import image from '../../Assets/background2.png'



const Banner = () => {
  return (
    <div className="container-fluid p-0">
      {/* Background image container */}
      <div className="position-relative">
        {/* Background image */}
        <img
          src={require('../../Assets/background2.png')} // Replace with your background image path
          alt="background"
          className="img-fluid w-100"
          style={{ height: '80vh', objectFit: 'cover' }}
        />

        {/* Overlay text */}
        <div className="position-absolute top-50 start-50 translate-middle text-center text-light">
          <h1 className="display-3 fw-bold">WELCOME TO <span className="text-success">CABLOW</span></h1>
          <p className="lead">
            Explore hundreds of intercity routes. Suggest your price and choose one of our verified drivers.
          </p>
        </div>

        {/* Input section */}
        <div className="position-absolute bottom-0 start-50   mb-5 translate-middle-x w-100">
          <div className="container">
            <div className="row bg-light p-4 rounded-pill shadow">
              <div className="col-md-2">
                <input type="text" className="form-control" placeholder="First Name " />
              </div>
              <div className="col-md-2">
                <input type="text" className="form-control" placeholder="Last Name" />
              </div>
              <div className="col-md-2">
  <input
    type="tel"
    className="form-control"
    placeholder="Mobile Number"
    pattern="[0-9]{10}" // This pattern ensures the user enters a 10-digit number
    required
  />
</div>
       

<div className="col-md-2">
  <input
    type="email"
    className="form-control"
    placeholder="Email"
    required
  />
</div>



              
              <div className="col-md-2">
                <button className="btn btn-success w-100">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
