
import React from 'react'

const Get_Taxi = () => {
  return (


    <div className='get_taxi'>
      <h3>GET TAXI NOW</h3>
      <h2 className='fw-bold' >Mob. no-7703924728</h2>
    </div>




  )
}

export default Get_Taxi