import React from 'react';
import './Card.css'; // Import the CSS file

import car5 from "../Assets/scooty.png";
import car6 from "../Assets/auto.png";
import car7 from "../Assets/car123.png";
import bike from "../Assets/bike1.png";


const Cards = () => {
  return (
    <div className="container my-4">
      <div className="tariff">
        <div className="tariff-top">
          <h4>SEE OUR</h4>
          <h2>OUR SERVICES</h2>
        </div>
      </div>
      <div className="row">
        {/* Card 1 */}
        <div className="col-md-6 col-lg-3   d-flex justify-content-center mb-4">
          <div className="card h-100 custom-card">
            <img
              src={car5}
              className="card-img-top"
              alt="Card 1"
            />
            <div className="card-body">
              <h3 className="card-title  fw-bold ">CAR</h3>
              <p className="card-text    ">
                This is a description for card 1. It provides some brief
                details about the content.
              </p>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-md-6 col-lg-3  d-flex justify-content-center mb-4">
          <div className="card h-100 custom-card">
            <img
              src={car6}
              className="card-img-top"
              alt="Card 2"
            />
            <div className="card-body">
              <h3 className="card-title fw-bold">BIKE</h3>
              <p className="card-text">
                This is a description for card 2. It provides some brief
                details about the content.
              </p>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-md-6 col-lg-3  d-flex justify-content-center mb-4">
          <div className="card h-100 custom-card">
            <img
              src={car7}
              className="card-img-top"
              alt="Card 3"
            />
            <div className="card-body">
              <h3 className="card-title fw-bold">AUTO</h3>
              <p className="card-text">
                This is a description for card 3. It provides some brief
                details about the content.
              </p>
            </div>
          </div>
        </div>

        {/* Card 4 */}
        <div className="col-md-6 col-lg-3  d-flex justify-content-center mb-4">
          <div className="card h-100 custom-card">
            <img
              src={bike}
              className="card-img-top"
              alt="Card 4"
            />
            <div className="card-body">
              <h3 className="card-title fw-bold">SCOOTY</h3>
              <p className="card-text">
                This is a description for card 4. It provides some brief
                details about the content.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
