// import React from "react";

import contact from "./contact/Contact"

import { Link } from 'react-router-dom'
// // hamburger 
// const Navbar = () => {
//   return (
//     <div className="Nav-bar">
//       <nav>
//         <div className="navbar_details">
//           <div>           
//             {/* <a href="#">Home</a> */}
//             <Link to='/'>Home</Link>
//           </div>
//           <div>           
//             {/* <a href="#">About</a> */}
//             <Link to='/gettaxi'>Get Taxi </Link>
//           </div>
//           <div>           
//             {/* <a href="#">Services</a> */}
//             <Link to='/services'>Services </Link>
//           </div>
//           <div>            
//             {/* <a href={contact}>Contact</a> */}
//             <Link to='/contact'>Contact </Link>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// };
// export default Navbar;



import React, { useState } from 'react';
import './Navbar.css';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* <a href="/" className="navbar-logo">
          Logo
        </a> */}
        <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
          <a href="/" className="navbar-item">Home</a>
          <a href="/gettaxi" className="navbar-item">Get Taxi</a>
          <a href="/services" className="navbar-item">Services</a>
          <a href="/about" className="navbar-item">About Us</a>

          <a href="/contact" className="navbar-item">Contact</a>
          {/* <a href="#" class="btn btn-primary btn-lg rounded-pill disabled" role="button" aria-disabled="true">DOWNLOAD APP</a> */}
          <button type="button" class="btn btn-warning  fw-bold">DOWNLOAD APP</button>

          {/* <a href="#" class="btn  btn-lg disabled" role="button" aria-disabled="true">DOWNLOAD OUR APP</a> */}
          
          {/* <a href="/appdownload" class="btn btn-lg disabled custom-btn" role="button" aria-disabled="true">DOWNLOAD OUR APP</a> */}

        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;